/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "../client/apollo";
import CookieConsent from "react-cookie-consent";

const Layout = ({ location, ...props }) => {
    const data = useStaticQuery(graphql`
        query Pages {
            scomos {
                pages(menu: "main") {
                    id: _id
                    parent
                    menuName
                    menu
                    url
                    lft
                }
            }
        }
    `);

    return (
        <>
            <ApolloProvider client={apolloClient}>
                <div className="relative">
                    <Header location={location} />
                    <main>{props.children}</main>
                </div>

                <footer className="bg-gray-800" aria-labelledby="footerHeading">
                    <h2 id="footerHeading" className="sr-only">
                        Footer
                    </h2>
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 text-gray-300">
                        <div className="pb-8 ">
                            <div className="  text-center text-lg font-light">
                                <p>
                                    Malý obchůdek s papírenským zbožím, kde
                                    najdete vše co potřebujete.
                                </p>
                                <p>
                                    Ať kupujete vybavení pro děti do školy, pro
                                    sebe domů, do kanceláře a nebo potřeby na
                                    tvoření či obdarování.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-orange-100 border-opacity-30 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                            <div>
                                <h3 className="text-lg font-semibold  tracking-wider  ">
                                    Papírnictví Eva Libišová - Elpap
                                </h3>
                                <p className="text-base  ">
                                    Husovo nám. 37/24 <br />
                                    266 01 Beroun
                                </p>
                            </div>
                            <div className="lg:text-right">
                                <p className="text-base ">+420 311 612 271</p>
                                <a
                                    href="mailto:info@elpap.cz"
                                    className="text-base "
                                >
                                    info@elpap.cz
                                </a>
                            </div>
                        </div>
                        <div className="mt-8 border-t border-orange-100 border-opacity-30 pt-8 md:flex md:items-center md:justify-between">
                            <div className="flex space-x-6 md:order-2"></div>
                            <p className="mt-8 text-base text-sky-100 opacity-70 md:mt-0  grid md:flex md:justify-between w-full">
                                <span>
                                    &copy; {new Date().getFullYear()} ELPAP
                                </span>
                            </p>
                        </div>
                    </div>
                </footer>
                <CookieConsent
                    location="bottom"
                    buttonText={
                        <div className="rounded-md bg-gray-100 px-6 py-2  hover:bg-gray-300">
                            Souhlasím
                        </div>
                    }
                    buttonWrapperClasses="w-full grid gap-4 lg:gap-2 "
                    declineButtonText={
                        <div className="rounded-md bg-gray-100 opacity-80 px-6 py-2 hover:bg-gray-300  ">
                            Nesouhlasím
                        </div>
                    }
                    enableDeclineButton
                    flipButtons={true}
                    disableStyles
                    overlay={true}
                    contentClasses="lg:col-span-2"
                    overlayClasses=" bg-gradient-to-b from-transparent to-black  z-[1000] h-[300px]  bottom-0 w-full fixed flex justify-center"
                    cookieName="gatsby-gdpr-google-tagmanager"
                    containerClasses="fixed transition   bg-gradient-to-br grid lg:grid-cols-3 gap-8 from-gray-600 to-gray-900  z-[1000] w-auto  lg:w-[700px]    lg:border border-gray-400 p-8 lg:rounded-md shadow-lg  lg:my-10  "
                >
                    <div className="text-xl font-bold   text-white mb-3  ">
                        Používáme cookies
                    </div>
                    <div className=" text-xs text-gray-100">
                        Základní cookies potřebujeme k zajištění správného
                        fungování stránek a volitelné k lepšímu pochopení toho,
                        jak s nimi pracujete. Ty druhé se aktivují po stisku
                        tlačítka Souhlasím.
                    </div>
                </CookieConsent>
            </ApolloProvider>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
