/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css";

import React from "react";

const App = (props) => {
    return props.children;
};

export const wrapRootElement = ({ element }) => <App>{element}</App>;
