import { Link } from "gatsby";
import React, { useState } from "react";
import { default as Logo } from "../images/elpap.svg";
import MainMenu from "./layout/MainMenu";

export default function Header({ menu, location }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="relative bg-gray-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:py-3">
                    <div className="flex justify-between items-center py-6 lg:justify-start  ">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to={"/"}>
                                <span className="sr-only">Elpa</span>
                                <img
                                    src={Logo}
                                    className="h-10 lg:h-12"
                                    alt="Logo"
                                />
                            </Link>
                        </div>
                        <div></div>
                        <div className="lg:order-1 flex">
                            <div className="lg:order-2 flex items-center justify-end lg:flex-1 lg:ml-10">
                                {/* <MiniCart  /> */}
                            </div>
                            <div className="lg:order-1 z-50 ">
                                <MainMenu
                                    open={open}
                                    setOpen={setOpen}
                                    menu={menu}
                                    location={location}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
